import { InformationCircle01Icon } from '@axieinfinity/dango-icons'
import { Dialog, Typography } from '@axieinfinity/konan'
import cx from 'classnames'
import { useAtomValue } from 'jotai'
import { useMemo } from 'react'

import { appVersionAtom } from '#/core/stores'
import { useCaptureEvent, useLoginDialog } from '#/hooks'
import { checkMavisIdSupported } from '#/utils'

import styles from './dialog.module.scss'
import { LoginForm } from './form'
import { IdLogin } from './id'
import { LoginQRCode } from './mobile-qr'

export const LoginDialog: React.FC = () => {
  const captureEvent = useCaptureEvent()
  const { isVisible, closeLoginDialog } = useLoginDialog()
  const appVersion = useAtomValue(appVersionAtom)

  const isMavisIdSupported = useMemo(() => {
    return checkMavisIdSupported(appVersion)
  }, [appVersion])

  return (
    <Dialog
      className={styles.dialog}
      wrapClassName={styles.dialogContainer}
      size={isMavisIdSupported ? 'large' : 'x-large'}
      destroyOnClose
      visible={isVisible}
      onClose={() => closeLoginDialog()}
    >
      <section
        className={cx(styles.body, {
          [styles.nonId]: !isMavisIdSupported,
        })}
      >
        <div className={styles.title}>Log in to Mavis Hub</div>

        <div
          className={styles.main}
        >
          <div className={styles.left}>
            {isMavisIdSupported ? <IdLogin /> : <LoginForm />}
          </div>
          <div className={styles.right}>
            <LoginQRCode />
          </div>
        </div>

        <footer className={styles.footer}>
          <Typography.Text className={styles.text}>
            <InformationCircle01Icon />
            By continuing, you agree to our
            <a
              className={styles.link}
              href="https://cdn.skymavis.com/files/terms-of-use.pdf"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => captureEvent('Click View Terms of Use')}
            >
              Terms of Use
            </a>
          </Typography.Text>
        </footer>
      </section>
    </Dialog>
  )
}
